<template>
	<canvas id="summary-chart"></canvas>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
	data () {
		return {
			chart: {}
		}
	},
  mounted () {
		const ctx = document.getElementById('summary-chart');
    this.chart = new Chart(ctx, {
			type: 'line',
			data: {
				labels: [],
				datasets: [{
					type: 'line',
					label: 'Rate',
					backgroundColor: '#04B016',
					borderColor: '#04B016',
					data: [],
					parsing: {
							yAxisKey: 'rate'
					},
					yAxisID: 'y1',
					pointRadius: 1
				}, {
					type: 'bar',
					label: 'Bought',
					backgroundColor: '#99ACEB',
					borderColor: '#99ACEB',
					maxBarThickness: 40,
					data: [],
					parsing: {
							yAxisKey: 'bought'
					},
					yAxisID: 'y',
				}]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: { display: false },
					tooltip: {
						callbacks: {
							label: () => '',
							afterBody: this.tooltip,
						}
					}
				},
				scales: {
					x: {
						grid: {
							display: false
						}
					},
					y: {
						type: 'linear',
						display: true,
						position: 'left',
						gird: {
							tickLength: 4
						},
						ticks: {
							callback: function(value) {
								return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'USD' }).format(value)
							}
						}
					},
					y1: {
						type: 'linear',
						display: true,
						position: 'right',
						max: 0.8,
						min: 0.45,
						grid: {
							display: false
						},
						ticks: {
							callback: function(value) {
								return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'USD' }).format(value)
							}
						}
					},
				}
			}
		})
	},
	watch: {
		chartData: function (val) {
			const { data } = this.chart
			data.labels = val.labels
			data.datasets.forEach(dataset => dataset.data = val.data)
			this.chart.update()
		}
	},
	methods: {
		tooltip (context) {
			const { raw } = context[0]
			const text = []
			text.push(`Sold: ${new Intl.NumberFormat('ja-JP').format(raw.sold.toFixed(2))}`)
			text.push(`Bought: $${new Intl.NumberFormat('ja-JP', { currency: 'USD' }).format(raw.bought.toFixed(2))}`)
			text.push(`Rate: $${raw.rate.toFixed(3)}`)

			return text
		}
	}
}
</script>