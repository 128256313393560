import { deepmerge, getProp } from '../libs/utils'
import UserRoles from '../configs/user-roles.json'


export const defaultState = {
  user: null,
  accessToken: null,
  apiToken: null,
  session: null,
  version: 1
}

const state = deepmerge(defaultState)

const mutations = {
  RESET_STATE (state) {
    const { user, accessToken, apiToken, ...others } = state // eslint-disable-line no-unused-vars
    mutations.SET_STATE(state, deepmerge(defaultState, others))
  },
  SET_STATE (state, payload = {}) {
    const newState = deepmerge(state, payload)
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_USER (state, payload = {}) {
    state.user = deepmerge(state.user, payload)
  },
  SET_SESSION (state, payload = {}) {
    state.session = { ...state.session, ...payload }
  },
  SET_VERSION (state, payload = 0) {
    state.version = payload
  }
}

const actions = {
  resetState ({ commit }) {
    commit('RESET_STATE')
  },
  setState ({ commit }, payload) {
    commit('SET_STATE', payload)
  },
  setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  },
  setSession ({ commit }, payload) {
    commit('SET_SESSION', payload)
  },
  setVersionToLatest ({ commit }) {
    commit('SET_VERSION', defaultState.version)
  }
}

const getters = {
  user (state) {
    return state.user
  },
  role (state) {
    if (!state.user) return null
    const roleName = getProp(state.user, 'role', UserRoles['USER'].name)
    return UserRoles[roleName]
  },
  session (state) {
    return state.session || {}
  },
  isOldVersion (state) {
    return state.version < defaultState.version
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
