<template>
  <div>
    <v-card class="elevation-section mb-6">
      <v-row no-gutters>
        <v-col>
          <div class="pa-2 elevation-section" tile>
            <div no-gutters class="d-flex flex-row pa-4 justify-space-between">
              <div class="flex-col">
                <v-row>
                  <v-col class="summary-left pr-6">
                    <v-row class="pl-6" style="margin: -10px"><span class="text-caption">Available</span></v-row>
                    <v-row class="align-center pl-6" style="min-width:150px">
                      <v-progress-circular indeterminate :size="20" v-if="balanceLoading"></v-progress-circular>
                      <div v-if="!balanceLoading">
                        <span class="text-h6 font-weigth-black">{{ totalBalance }}</span>
                        <span class="text-body-1 font-weight-thin pl-1">{{ currency }}</span>
                      </div>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="pl-6" style="margin: -10px"><span class="text-caption">Cold wallet</span></v-row>
                    <v-row class="align-center pl-6" style="min-width:150px">
                      <v-progress-circular indeterminate :size="20" v-if="balanceLoading"></v-progress-circular>
                      <div v-if="!balanceLoading">
                        <span class="text-h6 font-weigth-black">{{ coldBuffer }}</span>
                        <span class="text-body-1 font-weight-thin pl-1">{{ currency }}</span>
                      </div>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="pl-6" style="margin: -10px"><span class="text-caption">Buffer wallet</span></v-row>
                    <v-row class="align-center pl-6" style="min-width:150px">
                      <v-progress-circular indeterminate :size="20" v-if="balanceLoading"></v-progress-circular>
                      <div v-if="!balanceLoading">
                        <span class="text-h6 font-weigth-black">{{ clientBuffer }}</span>
                        <span class="text-body-1 font-weight-thin pl-1">{{ currency }}</span>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div class="flex-col">
                <v-btn color="primary" :disabled='true' class="px-4 py-0" @click="withdrawBase">
                  <v-icon left>mdi-arrow-collapse-down</v-icon>
                  <b>Withdraw</b>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <div class="mb-6">
      <v-card class="elevation-section">
        <v-row no-gutters>
          <v-col>
            <div class="pa-2 elevation-section" tile>
              <div no-gutters class="d-flex flex-row pa-4 justify-space-between">
                <div class="flex-col">
                  <v-row class="pl-6"><span class="text-caption">Available:</span></v-row>
                  <v-row class="align-center pl-6">
                    <v-progress-circular indeterminate :size="20" v-if="balanceLoading"></v-progress-circular>
                    <div v-if="!balanceLoading">
                      <span class="text-h6 font-weigth-black">{{ tpw }}</span>
                      <span class="text-body-1 font-weight-thin pl-1">{{ quoteCurrency }}</span>
                    </div>
                  </v-row>
                </div>
                <div class="flex-col">
                  <BankWithdrawButton :availableWithdraw="availableWithdraw">
                    <v-icon left>mdi-arrow-collapse-down</v-icon>
                    <b>Withdraw</b>
                  </BankWithdrawButton>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
	import AppService from '@/services/app.service'
	import { getProp } from '@/libs/utils'
	import BankWithdrawButton from '@/components/BankWithdrawButton'

	const formatter = new Intl.NumberFormat('en-US')
	const usdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

	export default {
		components: {
			BankWithdrawButton
		},
		props: ['currency', 'quoteCurrency'],
		data () {

			return {
				balanceLoading: false,
				clientBuffer: '-',
				coldBuffer: '-',
				tpw: '-',
				availableWithdraw: 0,
				totalBalance: '-',
				dialog: false
			}
		},
		mounted () {
      return this.updateBalances()
    },
		watch: {
			async currentUser () {
				return this.updateBalances()
			},
			async currency () {
				return this.updateBalances()
			}
		},
		computed: {
			currentUser () {
				return this.$store.getters['management/currentClient']
			}
		},
		methods: {
			async updateBalances() {
        this.balanceLoading = true
        let balances = await AppService.summary.balances(this.currency)
        let balancesResult = getProp(balances, 'result')
				
				const setDate = (balancesResult) => {
					const clientBuffer = (balancesResult.find(b => b.type === 'client_buffer') || { balance: 0 }).balance
					const tpw = (balancesResult.find(b => b.type === 'tpw') || { balance: 0 }).balance
					const coldBuffer = (balancesResult.find(b => b.type === 'cold_buffer') || { balance: 0 }).balance

					this.coldBuffer = formatter.format(coldBuffer)
					this.clientBuffer = formatter.format(clientBuffer)
					this.totalBalance = formatter.format(coldBuffer + clientBuffer)
					this.tpw = usdFormatter.format(tpw)
					this.availableWithdraw = tpw
				}

				if (balancesResult) {
					setDate(balancesResult)	
				} else {
					balances = await AppService.summary.balances(this.currency)
          balancesResult = getProp(balances, 'result')
					setDate(balancesResult)	
				}
				
        this.balanceLoading = false
      },
			withdrawBase () {
			//TODO:
			},
		}
	}
</script>
