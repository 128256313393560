import Vue from 'vue'

Vue.filter('dollar', (value, fixed = 2, symbol = '$') => {
  if (!value) return `${symbol}0`
  return `${symbol}${(+value).toLocaleString('en-US', {minimumFractionDigits: fixed, maximumFractionDigits: fixed})}`
})

Vue.filter('bitcoin', (value, fixed = 6, symbol = '₿') => {
  if (!value) return `${symbol}0`
  return `${symbol}${(+value).toLocaleString('en-US', {minimumFractionDigits: fixed, maximumFractionDigits: fixed})}`
})
