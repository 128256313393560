import Vue from 'vue'
import GAuth from 'vue-google-oauth2'
import VueNoty from 'vuejs-noty'
import App from './App.vue'
import './filters'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import router from './router'
import { store } from './store'
import appStore from './store/app'
import Toggle from '@/components/Toggle'
import FormInput from '@/components/FormInput'

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
Vue.use(VueNoty, {
  theme: 'metroui',
  layout: 'topRight',
  timeout: 3000,
  progressBar: true,
})

Vue.component('app-toggle-input', Toggle)
Vue.component('app-form-input', FormInput)

Vue.config.productionTip = false

appStore.instance = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
