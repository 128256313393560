import axios from 'axios'
import UserService from './user.service'
import { store } from '../store'
// import { getProp } from '../libs/utils'
// import Alert from '../libs/alert'

const {
  VUE_APP_API_URL: API_URL,
  VUE_APP_AUTHORIZER_URL: AUTHORIZER_URL,
  VUE_APP_AUTHORIZER_API_KEY: AUTHORIZER_API_KEY
} = process.env


// let previousErrorMessage = null
// let previousErrorTime = Date.now()
const request = async (verb, path, data, alert = true, options = {}) => {
  try {
    const isCheckAuthorizeRequest = path === '/auth/check'
    const serviceUrl = isCheckAuthorizeRequest ? AUTHORIZER_URL : API_URL
    const accessToken = await UserService.getAccessToken()
    const apiToken = isCheckAuthorizeRequest ? AUTHORIZER_API_KEY : await UserService.getApiToken()
    return await axios({
      ...options,
      headers: {
        'X-Api-Key': apiToken,
        'Authorization': `Bearer ${accessToken}`,
        ...(options.headers || {})
      },
      method: verb.toLowerCase(),
      url: `${serviceUrl}${path}`,
      ...(
        verb.toLowerCase() === 'get'
          ? { params: data }
          : { data }
      ),
    })
  } catch (error) {
    console.error(error, alert)
    // if (alert) {
    //   const message = getProp(error, ['response', 'data', 'error']) || getProp(error, ['message'])
    //   if (message !== previousErrorMessage || Date.now() - previousErrorTime > 1000) {
    //     Alert.error({
    //       title: 'Ooops!',
    //       message
    //     })
    //   }
    //   previousErrorMessage = message
    //   previousErrorTime = Date.now()
    // }
    throw error
  }
}

const service = {
  request,
  async checkAccessToken () {
    try {
      await request('GET', '/auth/check', undefined, false)
      return true
    } catch (e) {
      return false
    }
  },
  /**
   * Configuration
  */
  configuration: {
    async refreshCurrency () {
      const query = {}
      const managedUser = store.getters['management/currentClient']
      if (managedUser) {
        query.user = managedUser
      }

      const currency = await request('GET', `/api/currencies`, query).then(r => r.data)
      const find = currency.find(c => c.currency === store.getters['currency/fromCurrency'])
      const fromCurrency = find ? find.currency : currency[0].currency

      if (currency && currency.length > 0) {
        store.dispatch('currency/setFromCurrency', fromCurrency)
        store.dispatch('currency/setCurrencies',  currency.map(c => ({ from: c.currency, to: 'USD' }))) // FIXME: shouldn't hard code on to currency
      } else {
        store.dispatch('currency/resetState')
      }
      return currency
    },
    async get (currency) {
      const query = {}
      const managedUser = store.getters['management/currentClient']
      if (managedUser) {
        query.user = managedUser
      }
      return request('GET', `/api/configuration/${currency}`, query).then(r => r.data)
    },
    async update (currency, payload) {
      const query = {}
      const managedUser = store.getters['management/currentClient']
      if (managedUser) {
        query.user = managedUser
      }
      return request('POST', `/api/configuration/${currency}`, { ...payload, ...query }).then(r => r.data)
    },
    async networks () {
      return request('GET', `/api/network`).then(r => r.data)
    },
  },
  /**
   * info
  */
  info: {
    async exchanges (currency, quote) {
      return request('GET', `/api/info/exchanges/${currency}?quote=${quote}`).then(r => r.data)
    },
  },
  /**
   * Management
  */
  management: {
    async refreshClients () {
      const clients = await request('GET', `/api/info/clients`).then(r => r.data)
      store.dispatch('management/setClients', clients)
      const currentClient = store.getters['management/currentClient']
      const isManageableCurrentClient = store.getters['management/isManageableCurrentClient']
      if (!currentClient || !isManageableCurrentClient) {
        store.dispatch('management/setCurrentClient', clients[0])
      }
      return clients
    },
  },
  /**
   * Summary
  */
  summary: {
    async balances (currency) {
      const query = {}
      const managedUser = store.getters['management/currentClient']
      if (managedUser) {
        query.user = managedUser
      }

      return request('GET', `/api/sales/balances/${currency}`, query).then(r => r.data)
    },
    async summary (currency, from, to, timeFrame) {
      const query = { from, to, time_frame: timeFrame }
      const managedUser = store.getters['management/currentClient']
      if (managedUser) {
        query.user = managedUser
      }

      return request('GET', `/api/sales/summary-auto-transfer/${currency}`, query).then(r => r.data)
    },
    async withdraw ({ type, currency, withdrawCurrency, amount, toYellow }) {
      const query = { currency: withdrawCurrency, type, amount, toYellow }
      const managedUser = store.getters['management/currentClient']
      if (managedUser) {
        query.user = managedUser
      }

      return request('POST', `/api/sales/withdraw/${currency}`, query).then(r => r.data)
    }
  },
}

export default service
