<template>
  <v-switch v-model="activated" v-bind="$attrs">
    <template v-slot:label>
      <span class="toggle-caption pl-2" :class="{ 'green--text': activated, 'red--text': !activated }">
        {{activated ? 'On' : 'Off'}}
      </span>
      <span v-if="caption" class="text-caption pl-2 grey--text text--darken-1">
        {{caption}}
      </span>
    </template>
  </v-switch>
</template>

<script>
  export default {
    props: {
      value: { required: true },
      caption: { type: String },
    },
    data () {
      return {
        activated: this.value || false,
      }
    },
    watch: {
      value (v) {
        this.activated = v
      },
      activated (v) {
        this.$emit('input', v)
      },
    }
  }
</script>

<style lang="scss" scoped>
.toggle-caption {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.0178571429em;
}
</style>

