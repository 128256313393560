import { deepmerge } from '../libs/utils'
// document 
// https://vuex.vuejs.org/guide/actions.html
export const defaultState = {
    currentCurrency: {
      from: 'XRP',
      to: 'USD',
    },
    currencies: [{
      from: 'XRP',
      to: 'USD',
    }]
}

const state = deepmerge(defaultState)

const mutations = {
  RESET_STATE (state) {
    mutations.SET_STATE(state, deepmerge(defaultState)) // reset to use default state
  },
  SET_STATE (state, payload = {}) {
    const newState = deepmerge(state, payload)
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_FROM_CURRENCY (state, payload = "") {
    state.currentCurrency.from = payload
  },
  SET_TO_CURRENCY (state, payload = "") {
    state.currentCurrency.to = payload
  },
  SET_TO_CURRENCIES (state, payload = "") {
    state.currencies = payload
  }
}

const actions = {
  resetState ({ commit }) {
    commit('RESET_STATE')
  },
  setState ({ commit }, payload) {
    commit('SET_STATE', payload)
  },
  setFromCurrency ({ commit }, payload) {
    commit('SET_FROM_CURRENCY', payload)
  },
  setToCurrency ({ commit }, payload) {
    commit('SET_TO_CURRENCY', payload)
  },
  setCurrencies ({ commit }, payload) {
    commit('SET_TO_CURRENCIES', payload)
  }
}

const getters = {
  fromCurrency (state) {
    return state.currentCurrency.from
  },
  toCurrency (state) {
    return state.currentCurrency.to
  },
  currencies (state) {
    return state.currencies
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
