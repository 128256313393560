import AppService from './app.service'
import { store } from '../store'
import appStore from '../store/app'
import UserRoles from '../configs/user-roles.json'
import { getProp, isObject } from '../libs/utils'
import axios from 'axios'

const {
  VUE_APP_AUTHORIZER_URL: AUTHORIZER_URL,
  // VUE_APP_AUTHORIZER_API_KEY: AUTHORIZER_API_KEY
} = process.env

export default {
  ROLES: UserRoles,
  // async init () {
  //   await this.getUser()
  // },
  async isLoggedIn () {
    const user = await this.getUser()
    return user && !!await this.getAccessToken()
  },
  async getAccessToken () {
    return store.state.user.accessToken
  },
  async getApiToken () {
    return store.state.user.apiToken
  },
  async checkAccessToken () {
    return AppService.checkAccessToken()
  },
  async login () {
    const $gAuth = appStore.instance.$gAuth
    let user = {
      apiKey: undefined,
      jwt: undefined,
      username: undefined,
      role: undefined,
    }

    const googleResponse = await $gAuth.signIn();
    if (!googleResponse) {
      return
    }
    const googleUserAuth = googleResponse.getAuthResponse()
    const { 'id_token': token } = googleUserAuth
    try {
      const { result } = await axios.post(`${AUTHORIZER_URL}/auth/login`, { token }, {
        // headers: {
        //   'X-Api-Key': AUTHORIZER_API_KEY
        // }
      }).then(r => r.data)
      if (result['error']) {
        throw new Error(result['error'])
      }
      user = result
    } catch (e) {
      const error = (e.response && e.response.data.error) || e.message
      console.log('login', error)
      await this.logout()
      throw new Error(error)
    }
    let userRole = UserRoles[user.role] || UserRoles['USER']
    let state = {
      user: {
        id: googleResponse.getId(),
        name: user.username,
        role: userRole.name,
        // picture: jwtObject.picture,
      },
      accessToken: user.jwt,
      apiToken: user.apiKey,
    }
    await store.dispatch('user/setState', state)
    // console.log('login', state)
    return state
  },
  async logout () {
    const $gAuth = appStore.instance.$gAuth
    const gAuthUser = $gAuth && $gAuth.GoogleAuth && $gAuth.GoogleAuth.currentUser && $gAuth.GoogleAuth.currentUser.get()
    await Promise.all([
      gAuthUser && gAuthUser.disconnect(),
      store.dispatch('user/resetState')
    ])
    // console.log('logout', await this.getUser())
  },
  async getUser () {
    const user = await store.getters['user/user']
    // console.log('getUser', user)
    return user
  },
  hasPermission (permissionName = '', permissionProp = '') {
    if (!permissionName) return true
    const role = store.getters['user/role']
    if (!role) return false
    if (role.permissions === true) return true
    const permission = role.permissions[permissionName]
    if (isObject(permission) && permissionProp !== '') {
      const defaultAuthorize = getProp(permission, '_default', false)
      let authorized = getProp(permission, permissionProp)
      if (authorized === undefined) {
        authorized = getProp({ [permissionProp.split('.')[0]]: defaultAuthorize }, permissionProp, defaultAuthorize)
      }
      // console.log(permissionProp, authorized)
      return !!authorized
    }
    // console.log(permissionProp, permission)
    return !!permission
  }
}
