<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          disabled="false"
        >
          <slot></slot>
        </v-btn>
      </template>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title class="text-h4 grey lighten-2">
            Withdraw to Bank Account
          </v-card-title>
          <v-card-text class="py-8">
            <v-row>
              <v-col><span class="text-h6 app-sub-section-header">Available {{availableBalance | dollar}}</span></v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col class="py-0"><span class="text-h6 app-sub-section-header">Withdraw Amount</span></v-col>
              <v-col class="py-0">
                <app-form-input 
                  v-model="toWithdraw"
                  prefix="$"
                  type="number"
                  :customRule="validateWithdraw"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0"></v-col>
              <v-col class="py-0"><span>Fee 1% ({{fee | dollar}})</span></v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col><span class="text-h6 app-sub-section-header">To Account</span></v-col>
              <v-col><span class="text-h6 app-sub-section-header">{{toAccount | dollar}}</span></v-col>
            </v-row>
              <v-row><v-col><span class="app-sub-section-header">Name</span><span> {{bankInfo ? bankInfo.name : ''}}</span></v-col></v-row>
              <v-row><v-col><span class="app-sub-section-header">Account Number</span><span> {{bankInfo ? bankInfo.iban : ''}}</span></v-col></v-row>
              <v-row><v-col><span class="app-sub-section-header">Fedwire Routing Number</span><span> {{bankInfo ? bankInfo.bic : ''}}</span></v-col></v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" color="primary" @click="withdraw">Confirm</v-btn>
            <v-btn class="ma-2" @click="dialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
</template>

<script>
  import { getProp, round, debounce } from '@/libs/utils'
  import AppService from '@/services/app.service'

  export default {
    props: {
      availableWithdraw: Number
    },

    data () {
      const currency = 'XRP'
      const withdrawCurrency = 'USD'

      return {
        dialog: false,
        currency,
        withdrawCurrency,
        bankInfo: null,
        availableBalance: 0,
        toWithdraw: 0,
        loading: true
      }
    },

    async beforeMount () {
      await this.init()
    },

    watch: {
      async currentUser () {
        return this.init()
      },
      'availableWithdraw': {
        handler: debounce(function () {
          this.toWithdraw = this.availableWithdraw
          this.availableBalance = this.availableWithdraw
        }, 100)
      }
    },

    computed: {
      currentUser () {
        return this.$store.getters['management/currentClient']
      },
      toAccount () {
        return this.toWithdraw - this.fee 
      },
      fee () {
        return round(this.toWithdraw * 0.01, 2)
      },
      validateWithdraw () {
        return [ 
          !(this.toWithdraw > this.availableBalance) || `Withdraw amount must least than available balance ${this.availableBalance}`,
          !(this.toWithdraw <= 0 ) || "Withdraw amount must more than zero",
        ]
      }
    },

    methods: {
      async init () {
        const config = await AppService.configuration.get(this.currency)
        this.bankInfo = getProp(config, 'configuration.output.bank', null)
        this.loading = true
      },
      async withdraw () {
        try {
          if (this.$refs.form.validate()) {
            this.loading = true
            const result = await AppService.summary.withdraw({
              currency: this.currency,
              withdrawCurrency: this.withdrawCurrency,
              amount: this.toWithdraw,
              toYellow: this.fee
            })
            this.loading = false
            if (result) this.dialog = false
          }
        } catch (error) {
          console.log('error', error)
        }
      }
    }
  }
</script>