<template>
  <v-flex class="app-form-input">
    <label>
      <span class="app-form-input-caption text-caption" :class="labelClass">{{label}}</span>
      <component
        :is="component"
        v-model="content"
        :required="isRequired"
        :rules="rules"
        @keypress="v => $emit('keypress', v)"
        @keyup="v => $emit('keyup', v)"
        @blur="v => $emit('blur', v)"
        v-bind="attrs"
        :type="type"
        :class="componentClass"
        :label="undefined"
        :labelClass="undefined"
        :component="undefined"
      >
      </component>
    </label>
  </v-flex>
</template>

<script>
  import {
    VSelect,
    VTextField,
  } from 'vuetify/lib'
  import { defaultsDeep } from 'lodash'

  export default {
    components: {
      VSelect,
      VTextField,
    },
    props: {
      value: { required: true },
      label: { type: String },
      labelClass: { required: false },
      componentClass: { required: false },
      component: { default: 'v-text-field' },
      required: { required: false },
      type: { required: false, default: 'text' },
      customRule: { required: false, default: '' },
    },
    data () {
      return {
        content: this.value,
      }
    },
    watch: {
      value (v) {
        this.content = v
      },
      content (v) {
        this.$emit('input', v)
      },
    },
    computed: {
      attrs () {
        return defaultsDeep(this.$attrs, {
          solo: true,
          dense: true,
          'hide-details': false,
        })
      },
      isRequired () {
        if (this.required === '') return true
        return !!this.required
      },
      rules () {
        if (this.customRule) return this.customRule
        if (!this.isRequired) return
        const r = []
        r.push(v => !!v || '*required')
        switch (this.type) {
          case 'email':
            r.push(v => /.+@.+\..+/.test(v) || '*Email must be valid')
            break
        }
        return r
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>

