<template>
  <v-app light>
    <v-slide-y-transition mode="out-in" :duration="transitionDuration()">
      <div v-if="!appState.firstLoaded" style="position: relative; with: 100%; height: 100%; margin-top: 24px;">
        <PageLoader />
      </div>
      <router-view v-else />
      <!-- <v-container v-else class="page-loader" fluid fill-height>
        <v-layout justify-center align-center class="text-xs-center">
          <div class="animate logo d-flex align-center justify-center pa-1 mr-1" style="border-radius: 100%; height: 80px">
            <img src="/static/logo.png" class="fill-width fill-height">
          </div>
          <div class="animate loader d-flex justify-center align-center">
            <v-progress-circular indeterminate :size="35" :width="3" color="primary"></v-progress-circular>
          </div>
        </v-layout>
      </v-container> -->
    </v-slide-y-transition>
  </v-app>
</template>

<script>
  import appStore from './store/app'
  import PageLoader from './components/PageLoader'

  export default {
    components: { PageLoader },
    data () {
      return {
        appState: appStore.state,
        prevRouteNames: [],
      }
    },
    methods: {
      transitionDuration () {
        const routeName = this.$route.name
        const duration = this.prevRouteNames.includes('signin') ? 300 : 0
        if (this.prevRouteNames.slice(-1)[0] !== routeName) {
          this.prevRouteNames = [...this.prevRouteNames, routeName].slice(-2)
        }
        return duration
      }
    },
  }
</script>

<style lang="scss">
@import '@/styles/app.scss';
</style>

<style lang="scss" scoped>
  .page-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .animate {
    animation-duration: .5s;
    animation-fill-mode: backwards;
  }
  .logo {
    animation-name: logo;
    animation-delay: .1s;
  }
  .loader {
    animation-name: loader;
    animation-delay: .4s;
  }
  @keyframes logo {
    from { transform: translateX(25px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  @keyframes loader {
    from { transform: translateX(-25px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
</style>

