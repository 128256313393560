import _ from 'lodash'
import moment from 'moment'
// import { unflatten } from 'flat'

export const clone = _.cloneDeep
export const pickProp = _.pick
export const getProp = _.get

export const debounce = _.debounce
export const throttle = _.throttle
export const groupArrayByObjectKey = _.groupBy
export const objectValues = _.values
export const {
  uniq,
  uniqBy,
  sortedUniq,
  sortedUniqBy,
  min,
  minBy,
  max,
  maxBy,
  findIndex,
  pad,
  padStart,
  padEnd,
  isNil,
  escape,
  defaultsDeep,
  round
} = _
export const log10 = (() => {
  const LOG10 = Math.log(10)
  return (value, zeroValue = -1) => Math.log(value + 1 + zeroValue) / LOG10
})()
export const mapObjectByArrayKeysValues = (keys = [], arr = []) => {
  return arr.map(x => {
    return keys.reduce((c, k, i) => {
      c[k] = x[i]
      return c
    }, {})
  })
}

export const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]'
export const isArray = (obj) => Object.prototype.toString.call(obj) === '[object Array]'
export const isDate = (obj) => Object.prototype.toString.call(obj) === '[object Date]'
export const isNumber = (obj) => Object.prototype.toString.call(obj) === '[object Number]'
export const isBoolean = (obj) => Object.prototype.toString.call(obj) === '[object Boolean]'
export const isString = (obj) => Object.prototype.toString.call(obj) === '[object String]'
export const isNull = (obj) => Object.prototype.toString.call(obj) === '[object Null]'
export const isUndefined = (obj) => Object.prototype.toString.call(obj) === '[object Undefined]'
export const isFunction = (obj) => Object.prototype.toString.call(obj) === '[object Function]'

export const isObjectType = (obj) => obj === Object || isObject(obj)
export const isArrayType = (obj) => obj === Array || isArray(obj)
export const isDateType = (obj) => obj === Date || isDate(obj)
export const isNumberType = (obj) => obj === Number || isNumber(obj)
export const isBooleanType = (obj) => obj === Boolean || isBoolean(obj)
export const isStringType = (obj) => obj === String || isString(obj)
export const isNullType = (obj) => obj === null || isNull(obj)
export const isUndefinedType = (obj) => obj === undefined || isUndefined(obj)

export const isDefined = (obj) => !(obj === null || obj === undefined)

export const getDataType = (obj) => {
  switch (true) {
    case isObjectType(obj): return Object
    case isArrayType(obj): return Array
    case isDateType(obj): return Date
    case isNumberType(obj): return Number
    case isBooleanType(obj): return Boolean
    case isStringType(obj):
    case isNullType(obj):
    case isUndefinedType(obj):
    default: return String
  }
}

export const parseFormInputValue = (type, value) => {
  if (value === null || value === undefined) return value
  type = getDataType(type)
  switch (type) {
    case Date: return moment.utc(value).format('YYYY-MM-DDTHH:mm:ss')
    case Number: return isNumber(+value) && value !== '' ? +value : null
    case String:
    default: return value
  }
}

export const getFormInputValue = (type, value) => {
  if (value === null || value === undefined) return value
  type = getDataType(type)
  switch (type) {
    case Date: return moment.utc(value).format()
    case Array: return value.length ? value : undefined
    case String:
    default: return value
  }
}

export const getDateString = (date) => {
  return moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
}

export const getUtcDateTimestamp = (date) => {
  return +moment.utc(date).toDate()
}

export const debouncePromise = (fn = () => Promise.resolve(), timeout = 0) => {
  let progress = false
  return () => {
    if (!progress) {
      progress = true
      fn().then(args =>
        new Promise(resolve =>
          setTimeout(() => resolve(args), timeout)
        )
      ).then(args => {
        progress = false
        return args
      })
    }
  }
}

export const deepmerge = (...args) => _.mergeWith({}, ...args, (oldValue, newValue) => {
  if (_.isArray(newValue)) {
    return [...newValue]
  }
})

export const deepmergeWithArray = (...args) => _.merge({}, ...args)

export const objectToArray = (obj) => {
  return Object.keys(obj).map(key => ({ key, value: obj[key] }))
}

export const titleCase = (text) => {
  const textParts = text.split('.')
  return [
    textParts[0].split('_').join(' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()),
    ...textParts.slice(1)
  ].join('.')
}

export const titleText = (text) => {
  // return titleCase(text).split('.').reduce((c, t) => {
  //   c = c === '' ? t : `${c}[${t}]`
  //   return c
  // }, '')
  return titleCase(text)
}

export const flattenObject = (o, condition = null) => {
  const result = {}
  Object.keys(o).forEach(key => {
    const prop = o[key]
    if (condition ? condition(prop, key) : !(isObject(prop) || isArray(prop))) {
      result[key] = prop
    } else {
      const tmp = flattenObject(prop, condition)
      Object.keys(tmp).forEach(tmpKey => {
        result[`${key}.${tmpKey}`] = tmp[tmpKey]
      })
    }
  })
  return result
}

export const unflattenObject = (o, delimiter = '.') => {
  let result = {}
  Object.keys(o).forEach(key => {
    const paths = key.split(delimiter)
    let tmp = result
    for (const i in paths) {
      const path = paths[i]
      if (!Object.prototype.hasOwnProperty.call(tmp, path)) {
        tmp[path] = {}
      }
      if (+i === paths.length - 1) {
        tmp[path] = o[key]
      } else {
        tmp = tmp[path]
      }
    }
  })
  const valueObjects = Object.values(o).filter(x => isObject(x))
  result = convertObjectNumericKeyToArray(result, valueObjects)
  return result
}

export const convertObjectNumericKeyToArray = (o, ignore = []) => {
  if (isObject(o) && !ignore.includes(o)) {
    if (Object.keys(o).length && isSequenceNumber(Object.keys(o))) {
      const arr = []
      Object.keys(o).forEach(key => {
        arr[+key] = convertObjectNumericKeyToArray(o[key], ignore)
      })
      return arr
    } else {
      Object.keys(o).forEach(key => {
        o[key] = convertObjectNumericKeyToArray(o[key], ignore)
      })
    }
  }
  return o
}

export const isSequenceNumber = (numbers = []) =>
  numbers.every((number, i) => !isNaN(number) && (i === 0 || number - numbers[i - 1] === 1))

export const sortObjectKeys = (obj = {}, sortKeys = []) => {
  if (!isObject(obj)) return obj
  if (sortKeys.length > 0) {
    let result = {}
    const keys = Object.keys(obj).filter(key => !sortKeys.includes(key))
    sortKeys.forEach(key => (result[key] = sortObjectKeys(obj[key])))
    keys.forEach(key => (result[key] = sortObjectKeys(obj[key])))
  }
  return obj
}

export const getUniqueId = (() => {
  let x = 0
  return () => {
    return (+(`${x++}${Date.now()}`)).toString(36)
  }
})()

export const removeEmptyProps = (obj) => {
  for (var key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

export const mergeObjectKeyFromArray = (arr = [], ignore = []) => {
  // FIXME: not correctly
  const keys = Object.keys(arr.reduce((c, item) => {
    Object.keys(item || {}).forEach(key => {
      if (!ignore.includes(key)) {
        c[key] = true
      }
    })
    return c
  }, {}))
  return keys
}

export const compareString = (str1 = '', str2 = '') => {
  return str1 > str2 ? 1 : (str1 < str2 ? -1 : 0)
}

export const sortByString = (arr = [], propPath = undefined) => {
  return arr.sort((a, b) => {
    const aValue = propPath ? getProp(a, propPath) : a
    const bValue = propPath ? getProp(b, propPath) : b
    const aLower = aValue.toLowerCase()
    const bLower = bValue.toLowerCase()
    const lowerResult = compareString(aLower, bLower) // ignore casesensitive
    if (lowerResult !== 0) return lowerResult
    const strResult = -1 * compareString(aValue, bValue) // lower case first
    return strResult
  })
}

export const hexToRGBA = (hex, alpha) => {
  const isShortcut = hex.length === 4
  const r = parseInt(isShortcut ? hex.slice(1, 2) + hex.slice(1, 2) : hex.slice(1, 3), 16)
  const g = parseInt(isShortcut ? hex.slice(2, 3) + hex.slice(2, 3) : hex.slice(3, 5), 16)
  const b = parseInt(isShortcut ? hex.slice(3, 4) + hex.slice(3, 4) : hex.slice(5, 7), 16)
  if (alpha !== undefined) {
    return `rgba(${r},${g},${b},${alpha})`
  } else {
    return `rgb(${r},${g},${b})`
  }
}

export const findArrayByMatchingProperties = (arr = [], obj = {}) => {
  return arr.filter(entry => {
    return Object.keys(obj).every(key => {
      return entry[key] === obj[key]
    })
  })
}

export function copyToClipboard (text) {
  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

import c from '@/assets/country.json'
export const country = c
