import { deepmerge } from '../libs/utils'


export const defaultState = {
  clients: [],
  currentClient: null,
}

const state = deepmerge(defaultState)

const mutations = {
  RESET_STATE (state) {
    const { client, accessToken, apiToken, ...others } = state // eslint-disable-line no-unused-vars
    mutations.SET_STATE(state, deepmerge(defaultState, others))
  },
  SET_STATE (state, payload = {}) {
    const newState = deepmerge(state, payload)
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_CLIENTS (state, payload = []) {
    state.clients = payload
  },
  SET_CURRENT_CLIENT (state, payload) {
    state.currentClient = payload
  },
}

const actions = {
  resetState ({ commit }) {
    commit('RESET_STATE')
  },
  setState ({ commit }, payload) {
    commit('SET_STATE', payload)
  },
  setClients ({ commit }, payload) {
    commit('SET_CLIENTS', payload)
  },
  setCurrentClient ({ commit }, payload) {
    commit('SET_CURRENT_CLIENT', payload)
  }
}

const getters = {
  clients (state) {
    return state.clients
  },
  currentClient (state) {
    return state.currentClient
  },
  isManageableCurrentClient (state) {
    return state.clients.includes(state.currentClient)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
