import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import UserService from '../services/user.service'
import { store } from '../store'
import appStore from '../store/app'
import { sync } from 'vuex-router-sync'
// import Alert from '../libs/alert/'
import { isFunction } from '../libs/utils'
const SignIn = () => import(/* webpackChunkName: "signin" */ '@/views/SignIn')
const Layout = () => import(/* webpackChunkName: "main" */ '@/Layout')
import Summary from '@/views/Summary/Summary'
// import Execution from '@/views/Execution/Execution'
// import Configuration from '@/views/Configuration/Configuration'

Vue.use(Router)

const defaultPage = 'summary'

const delayFirstTime = (() => {
	const MIN_DELAY_TIME = 0
	let isFirstTime = false
	return (time, next) => {
		if (!isFirstTime) {
			isFirstTime = true
			const diff = Date.now() - time
			const wait = MIN_DELAY_TIME - diff
			setTimeout(next, wait < 0 ? 0 : wait)
		} else {
			next()
		}
	}
})()
const router = new Router({
	mode: 'history',
  base: process.env.BASE_URL,
	routes: [
		{
			path: '/signin',
			name: 'signin',
			component: SignIn,
			meta: {
				loader: true
			},
			async beforeEnter(to, from, next) {
				const time = Date.now()
				if (await UserService.isLoggedIn()) {
					if (await UserService.checkAccessToken()) {
						delayFirstTime(time, () => next('/'))
					} else {
						UserService.logout()
						delayFirstTime(time, () => next())
					}
				} else {
					delayFirstTime(time, () => next())
				}
			}
		},
		{
			path: '/',
			component: Layout,
			meta: {
				loader: true
			},
			async beforeEnter(to, from, next) {
				const time = Date.now()
				// Check if store is old version, then logout automatically.
				const idOldVersion = store.getters['user/isOldVersion']
				await store.dispatch('user/setVersionToLatest')
				if (idOldVersion) {
					UserService.logout()
					delayFirstTime(time, () => next({ name: 'signin', query: { redirect: to.fullPath } }))
					return
				}
				if (!await UserService.isLoggedIn()) {
					delayFirstTime(time, () => next({ name: 'signin', query: { redirect: to.fullPath } }))
				} else {
					if (await UserService.checkAccessToken()) {
						delayFirstTime(time, () => next())
					} else {
						// Alert.error({
						// 	emergency: true,
						// 	title: 'Attention',
						// 	message: 'You have disconnected or your <b>`Access Token`</b> is not working'
						// })
            console.error(JSON.stringify({
							emergency: true,
							title: 'Attention',
							message: 'You have disconnected or your <b>`Access Token`</b> is not working'
						}))
						UserService.logout()
						delayFirstTime(time, () => next({ name: 'signin', query: { redirect: to.fullPath } }))
					}
				}
			},
			children: [
				{
					path: '',
					redirect: () => {
						const role = store.getters['user/role']
						if (role) {
							switch (role.name) {
								default: return { name: defaultPage }
							}
						}
						return { name: 'signin' }
					},
					meta: {
						show: false
					}
				}, {
					path: 'summary',
					name: 'summary',
					component: Summary,
					meta: {
						exact: false,
						title: 'Summary'
					},
				}
			]
		},
		{
			path: '*',
			redirect: '/'
		}
	]
})
router.beforeEach(async (to, _, next) => {
	if (to.matched.some(m => m.meta.loader)) {
		appStore.state.loading = true
	}
	if (to.meta.permission) {
		const permissionParams = isFunction(to.meta.permission) ? to.meta.permission(to) : [to.meta.permission]
		const authorized = await UserService.hasPermission(...permissionParams)
		// console.log('permission', permissionParams, authorized)
		if (authorized) {
			return next()
		} else {
			return next('/')
		}
	}
	next()
})
router.afterEach(() => {
	appStore.state.loading = false
	setTimeout(() => goTo(0, { duration: 0 }), 250)
})

sync(store, router)

export default router
