import Vue from 'vue'

export default {
  instance: undefined,
  state: new Vue({
    data: {
      loading: true,
      firstLoaded: false,
    },
    watch: {
      loading () {
        if (!this.loading && !this.firstLoaded) {
          this.firstLoaded = true
        }
      },
    },
  })
}
