<template>
  <div class="summary">
    <Balance  v-bind:currency="currency" v-bind:quoteCurrency="quoteCurrency"/>
    <v-row>
      <v-col md="5">
        <v-card class="elevation-section">
          <v-row no-gutters>
              <v-tabs grow centered class="time-tabs">
                <v-tab :disabled=tableLoading v-on:click="activetab=1; handleTabChange()" v-bind:class="[ activetab === 1 ? 'active' : '' ]">
                  Year
                </v-tab>
                <v-tab :disabled=tableLoading v-on:click="activetab=2; handleTabChange()" v-bind:class="[ activetab === 2 ? 'active' : '' ]">
                  Month
                </v-tab>
                <v-tab :disabled=tableLoading v-on:click="activetab=3; handleTabChange()" v-bind:class="[ activetab === 3 ? 'active' : '' ]">
                  Week
                </v-tab>
              </v-tabs>
          </v-row>

          <v-row no-gutters class="d-flex">
            <v-col v-if="activetab===1" class="d-flex" style="width: 503px;">
              <v-tabs height="36px" show-arrows active-class="selected-time" v-model=selectedYear v-on:change=handleTabChange()>
                <v-tabs-slider class="time-slider"></v-tabs-slider>
                <v-tab v-for="item in years" :key="item" :ripple="false">
                  <span class="slide-grid-text">{{ item }}</span>
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col v-else-if="activetab===2" class="d-flex" style="width: 503px;">
              <v-tabs height="36px" show-arrows active-class="selected-time" v-model=selectedMonth v-on:change=handleTabChange()>
                <v-tabs-slider class="time-slider"></v-tabs-slider>
                <v-tab v-for="item in months" :key="item" :ripple="false"> 
                  <span class="slide-grid-text">{{ item }}</span>
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col v-else-if="activetab===3" class="d-flex" style="width: 503px;">
              <v-tabs height="36px" show-arrows active-class="selected-time" v-model=selectedWeek v-on:change=handleTabChange()>
                <v-tabs-slider class="time-slider"></v-tabs-slider>
                <v-tab v-for="item in weeks" :key="item" :ripple="false">
                  <span class="slide-grid-text">{{ item }}</span>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>  

          <v-row class="mt-4">
            <v-col class="d-flex overflow-auto scrollable pt-0" style="height: 420px;">
              <v-simple-table
              dense
              class="table-alternate-color"
              flat>
              <template v-slot: default>
                <col style="width:30%; text-align:left;" />
                <col style="width:25%; text-align:right;" />
                <col style="width:25%; text-align:right;" />
                <col style="width:20%; text-align:right;" />
                <thead>             
                  <tr fixed>
                    <th v-for="item in headers" :key="item.value">
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="tableLoading">
                    <td colspan="4" style="text-align:center"> Loading... </td>
                  </tr>
                  <tr v-if="sales.length === 0 && !tableLoading">
                    <td colspan="4" style="text-align:center"> No data </td>
                  </tr>
                  <tr fixed v-for="item in sales" :key="item.date">
                    <td style="text-align:left;">{{ item.date }}</td>
                    <td style="text-align:right;">{{ item.sold }}</td>
                    <td style="text-align:right;">{{ item.bought }}</td>
                    <td style="text-align:right;">{{ item.rate }}</td>
                  </tr>
                </tbody>  
              </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row no-gutters flat tile class="total-row mt-4">
            <v-col flat tile no-gutter>
            <v-simple-table
              dense
              flat
              class="pt-2 pb-3 font-weight-bold rounded-0">
              <template v-slot: default>
                <col style="width:30%" />
                <col style="width:25%" />
                <col style="width:25%" />
                <col style="width:20%" />
                <tbody>
                  <tr>
                    <td style="text-align:left;">Total</td>
                    <td style="text-align:right;">{{ total.sold }}</td>
                    <td style="text-align:right;">{{ total.bought }}</td>
                    <td style="text-align:right;">{{ total.rate }}</td>
                  </tr>
                </tbody>  
              </template>
            </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
        <div v-bind="attrs" v-on="on" v-on:click="csvExport()"
          class="text-link mt-2 d-flex justify-end">
          Export CSV
        </div>
      </v-col>
      <v-col md="7">
        <v-card tile class="summary-graph pa-2 elevation-section">
          <SummaryChart v-bind:chartData="chartData"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { clone } from '@/libs/utils'
  import AppService from '@/services/app.service'
  import appStore from '@/store/app'
  import moment from 'moment'
  import SummaryChart from './SummaryChart'
  import Balance from './Balance'

  const MONTH_FORMAT = 'MMM YYYY'
  const YEAR_FORMAT = 'YYYY'
  const WEEK_FORMAT = 'W/YYYY'

	const formatter = new Intl.NumberFormat('en-US')
	const usdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

  export default {
    components: { 
      Balance,
      SummaryChart
    },
    data () {
      return {
        activetab: 1,
        selectedWeek: 0,
        selectedMonth: 0,
        selectedYear: 0,
        tableLoading: false,
        months: [],
        years: [],
        weeks: [],
        total: {
          sold: 0,
          bought: 0,
          rate: '-'
        },
        rawData: [],
        sales: [],
        chartData: {},
        headers: [],
      }
    },

    async beforeMount () {
      await this.init()
    },

    mounted () {
      this.selectedWeek = this.weeks.length - 1
      this.selectedMonth = this.months.length - 1
      this.selectedYear = this.years.length - 1

      return this.handleTabChange()
    },

    watch: {
      async currentUser () {
        return this.handleTabChange()
      },
      async currency () {
        return this.handleTabChange()
      }
    },

    computed: {
      currentUser () {
        return this.$store.getters['management/currentClient']
      },
      currency () {
        return this.$store.getters['currency/fromCurrency']
      },
      quoteCurrency () {
        return this.$store.getters['currency/toCurrency']
      }
    },
    methods: {
      async init () {
        appStore.state.loading = true

        // init period
        this.months = this.initTimePeriod(12, 'months', moment.utc().startOf('month'), MONTH_FORMAT)
        this.weeks = this.initTimePeriod(52, 'weeks', moment.utc().startOf('isoWeek'), WEEK_FORMAT)
        this.years = this.initTimePeriod(2, 'years', moment.utc().startOf('year'), YEAR_FORMAT)

        appStore.state.loading = false
        this.ready = true
      },
      initTimePeriod(minPeriod, period, momentStart, format) {
        const curr = momentStart.subtract(minPeriod, period)
        const result = []
        for (let i = 0; i < minPeriod; i++) {
          curr.add(1, period);
          result.push(curr.format(format))
        }

        return result
      },
      getFormat () {
        let format = ''
        let add = ''
        let timeFrame = 'day'
        let dateFormat = 'ddd D/MM/YYYY'
        let seletedPeriod = {}

        switch (this.activetab) {
          case 1: // year
            format = YEAR_FORMAT
            add = 'y'
            timeFrame = 'month'
            dateFormat = 'MMM YYYY'
            seletedPeriod = this.years[this.selectedYear]
            break
          case 2: // month
            format = MONTH_FORMAT
            add = 'M'
            seletedPeriod = this.months[this.selectedMonth]
            break
          case 3: // week
            format = WEEK_FORMAT
            add = 'w'
            seletedPeriod = this.weeks[this.selectedWeek]
            break
        }

        return { format, add ,timeFrame, dateFormat, seletedPeriod }
      },
      setPrecision (item, key) {
        const precision = {
          rate: 3,
          bought: 2,
          sold: 2
        }

        return item[key] ? Math.floor(item[key] * Math.pow(10, precision[key])) / Math.pow(10, precision[key]) : 'N/A'
      },
      async handleTabChange() {
        try {
        this.tableLoading = true
        const { format, add, timeFrame, seletedPeriod, dateFormat } = this.getFormat()
        this.headers = [
          { text: 'DATE', value: 'date'},
          { text: `${this.currency} SOLD`, value: 'token_sold' },
          { text: `${this.quoteCurrency} BOUGHT`, value: 'usd_bought' },
          { text: 'RATE', value: 'rate'}
        ]
        this.sales = []
        this.chartData = {
          labels: [],
          bought: [],
          rate: [],
        }
        this.total = {
          sold: 0,
          bought: 0,
          rate: '-'
        }
      
        const from = moment.utc(seletedPeriod, format)
        const to = moment.utc(seletedPeriod, format).add(1, add)
        const result = await AppService.summary.summary(this.currency, from.toISOString(), to.toISOString(), timeFrame)
        const { data, total } = result.result

        this.tableLoading = false

        if (data.length > 0) {
          if (data) {
            // update table
            this.sales = clone(data)
            .filter(item => item.sold !== 0)
            .map(d => {
              d.date = moment(d.date).format(dateFormat)
              d.rate = `$${this.setPrecision(d, 'rate')}`

              d.sold = formatter.format(this.setPrecision(d, 'sold'))
              d.bought = usdFormatter.format(this.setPrecision(d, 'bought'))

              return d
            })

            // update chart
            this.chartData = {
              labels: data.map(d => moment(d.date).format(dateFormat)),
              data: data.map(d => ({
                  bought: d.bought,
                  sold: d.sold,
                  rate: d.rate,
                  x: moment(d.date).format(dateFormat)
              }))
            }
          }
          if (total) {
            total.rate = `$${this.setPrecision(total, 'rate')}`

            total.sold = formatter.format(this.setPrecision(total, 'sold'))
            const bought = this.setPrecision(total, 'bought')
            total.bought = usdFormatter.format(bought)
            
            this.total = total
          }
        }
        } catch (e) {
          console.error(e)
        }
      },
      async csvExport () {
        let csvContent = 'data:text/csv;charset=utf-8,'

        const from = moment.utc('01/01/1970') // start date
        const to = moment.utc()
        const result = await AppService.summary.summary(this.currency, from.toISOString(), to.toISOString(), 'day')

        const csvFormat = (arrData, total) => {          
        const header = ['Date',	`${this.currency} sold`,	`${this.quoteCurrency} bought`,	'Rate']
  try{
          const setNumberDecimal = (amount, digit = 8) => {
            return amount ? amount.toFixed(digit) : 'N/A'
          }

          const totalRow = [
            'TOTAL',
            setNumberDecimal(total.sold, 2),
            setNumberDecimal(total.bought, 2),
            setNumberDecimal(total.rate)
          ]
          const order = [{
            format: (item) => item.date ? moment.utc(item.date).format('YYYY-MM-DD') : '' // date
          },{
            format: (item) => setNumberDecimal(item.sold, 2)
          },{
            format: (item) => setNumberDecimal(item.bought, 2)
          },{
            format: (item) => setNumberDecimal(item.rate)
          }] 

          const data = arrData
          .filter(item => item.sold !== 0)
          .map(item => {
            return Object.values(order.map(o => o.format(item))).join(',')
          })
          return [
            header.join(','),
            totalRow.join(','),
            ...data
          ].join('\n')
  } catch (e) {
    console.error(e)
  }

        }

        const { data, total } = result.result

        csvContent += csvFormat(data, total)
        const firstDate = moment.utc(data[0].date).format('YYYY-MM-DD')
        const endDate = moment.utc(data[data.length - 1].date).format('YYYY-MM-DD')
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csvContent))
        link.setAttribute('download', `Export_Report_${firstDate}_${endDate}.csv`)
        link.click()
      }
    }
  }
</script>
