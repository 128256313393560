import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import userModule from './user'
import managementModule from './management'
import currencyModule from './currency'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    user: userModule,
    management: managementModule,
    currency: currencyModule,
  },
  plugins: [createPersistedState({
    key: 'tradepoint',
    paths: ['user', 'management.currentClient']
  })]
})
